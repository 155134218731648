import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import MUIList from '@material-ui/core/List';
import MUIListItem, { ListItemTypeMap } from '@material-ui/core/ListItem';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const Group = styled(Box)``;

export const ListItem: OverridableComponent<
  ListItemTypeMap<{ button?: false }, 'li'>
> = styled(MUIListItem)`
  ${({
    theme: {
      transitions: { duration, easing },
      palette,
      colors,
    },
  }) => `

    font-weight: 400;
    font-size: 14px;
    padding: 6px 0;
    align-items: center;
    margin: auto;
    justify-content: center;

    & > a {
      color: #003C3B;
      transition: ${duration.shorter}ms ${easing.easeInOut};

      &:hover,
      &:active,
      &:focus {
        color: #003C3B;
        text-decoration: underline;
      }
    }
  `}
`;

export const List = styled(MUIList)`



  ${({ theme: { breakpoints } }) => `
    display: flex;
 
    flex-wrap: wrap;
    width: 100%;
    height: 50px;

    ${breakpoints.up('md')} {
      width: 50%;
      height: unset;
    }


    ${breakpoints.up('lg')} {
      flex-wrap: nowrap;
      margin: auto;
    }
  `}
`;

/*** Config file ***/
type Config = {
  path?: string;
  apiPath: string;
  apiId: number;
  cmsApi: string;
  sageLeadApi: string;
  GTMId: string;
  googleSearchApiKey?: string;
  googleMapApiKey?: string;
  domainUrl?: string;
  QCChoiceMSGId?: string;
  nodeEnv?: string;
  vercelEnv?: string;
};

export const config: Config = {
  path: process.env.NEXT_PUBLIC_API_HOST,
  apiPath: 'api/rest/portal',
  apiId: Number(process.env.NEXT_PUBLIC_API_ID),
  cmsApi: process.env.NEXT_PUBLIC_CMS_API_HOST ?? '',
  sageLeadApi: process.env.NEXT_PUBLIC_SAGE_LEAD_API_HOST ?? '',
  GTMId: process.env.NEXT_PUBLIC_GTM_ID ?? '',
  googleSearchApiKey: process.env.NEXT_PUBLIC_GOOGLE_SEARCH_API_KEY,
  googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  domainUrl: process.env.NEXT_PUBLIC_DOMAIN_URL,
  QCChoiceMSGId: process.env.NEXT_PUBLIC_QCC_MSG_ID,
  nodeEnv: process.env.NODE_ENV,
  vercelEnv: process.env.NEXT_PUBLIC_VERCEL_ENV,
};

import axios, { AxiosRequestConfig } from 'axios';

import { config as appConfig } from '../config/config';
import { Methods } from './api.types';

const {apiId} = appConfig;

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Portal-Id': apiId,
};

type RequestArgs = {
  method?: Methods;
  query: string;
  headers?: Record<string, any>;
  params?: any;
  data?: any;
  host?: string;
};

export const request = async ({
  method = Methods.GET,
  query,
  headers: customHeaders,
  params,
  data,
  host,
}: RequestArgs) => {
  const { path, apiPath } = appConfig;
  const generatePath = host ?? path;

  const config: AxiosRequestConfig = {
    method,
    url: `${generatePath}/${apiPath}/${query}`,
    headers: { ...DEFAULT_HEADERS, ...customHeaders },
    params,
    data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error: any) {
    return Promise.reject({
      ...error.response.data,
      statusCode: error.response.status,
      notFound: error.response.status === 404,
    });
  }
};

import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Base = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-height: 100vh;
  overflow: hidden;
`;

export const Main = styled(Box)`
  ${({ theme: { breakpoints, sizes, colors } }) => `
    flex: 1 1 auto;
    padding-top: ${sizes.headerHeight.sm};
    background: ${colors.white};

    ${breakpoints.up('md')} {
      padding-top: ${sizes.headerHeight.default};
    }
  `}
`;

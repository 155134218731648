import { useState } from 'react';
import { useRouter } from 'next/router';

import {
  getPartnersParams,
  getSearchBasicParams,
} from '@keaze/web/utils/params';

export const useHeader = () => {
  const router = useRouter();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const partnerParams = getPartnersParams(router.query);
  const { type, ...searchBasicParams } = getSearchBasicParams(router.query);
  const toggleDrawer = () => setDrawerOpen((prevState) => !prevState);

  return {
    drawerOpen,
    toggleDrawer,
  };
};
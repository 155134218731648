import styled from 'styled-components';
import MUITypography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export const Footer = styled(MUIGrid)`
  ${({ theme: { palette, breakpoints } }) => `
  width: 100%;
    background-color: #F2F1F0;
    padding: 0px;
    margin-top: 0;
    display: flex; ;
    width: 100%;
    ul {
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;

 
      li {
        a {font-size: 10px;}
 
       
        height: 10px;
      }
      li:not(:last-child) {
    
 
        border-right 1px solid #30302E;
   
      }
    }

    ${breakpoints.up('md')} {
      padding: 20px;
      ul {

      }
    }
  `}
`;

export const Grid = styled(MUIGrid)`
  ${({ theme: { palette, breakpoints } }) => `
  justify-content: center;
    & > .MuiGrid-item {
      width: 100%;
  

      ${breakpoints.up('lg')} {
        margin: auto;
        position: relative;
        top: 1rem;
      
      }
    }
  `}
`;

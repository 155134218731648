import React, { FC, memo, useState } from 'react';
import dynamic from 'next/dynamic';
import Container from '@material-ui/core/Container';

import { config } from '@keaze/web/config';
import { ProgressBarProps } from '@keaze/web/components/progressBar';
import { Base, Main } from './base.styles';
import { Header } from './header';
import { Footer } from './footer';
import CmsApi from '@keaze/web/utils/cmsApi';
import { Link as LinkType } from '@keaze/web/layouts/base/base.types';

export type baseLayoutProps = {
  children: React.ReactNode;
};

const DynamicAuth = dynamic(
  () => import('@keaze/web/components/auth').then((model) => model.Auth),
  {
    ssr: false,
  }
);

const DynamicProgressBar = dynamic<ProgressBarProps>(
  () =>
    import('@keaze/web/components/progressBar').then(
      (model) => model.ProgressBar
    ),
  {
    ssr: false,
  }
);

export const BaseLayout: FC<baseLayoutProps> = memo(({ children }) => {
  const [headerLinks, setHeaderLinks] = useState<LinkType[]>([
    {
      name: '',
      href: '',
    },
  ]);

  const [footerLinks, setFooterLinks] = useState<LinkType[]>([
    {
      name: '',
      href: '',
    },
  ]);

  if (headerLinks[0].name == '' || footerLinks[0].name == '') {
    CmsApi.getLinksFromApi()
      .then((data) => {
        setHeaderLinks(data[0].header);
        setFooterLinks(data[0].footer);
      })
      .catch((error) => console.error(error.message));
  }

  return (
    <Base>
      <Header links={headerLinks} />
      <Main component="main">
        <Container>
          <>{children}</>
        </Container>
      </Main>
      <Footer links={footerLinks} />
      <DynamicAuth />
      <DynamicProgressBar />
      <div id={config.QCChoiceMSGId} />
    </Base>
  );
});

import {FC, memo, useState} from 'react';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';


const S = {

  newGrid: styled(Grid)`
  width 100%;
  `,
}
import {
  Footer as UIFooter,
  Grid,
} from './footer.styles';
import { Links } from './links';
import {Link as LinkType} from "@keaze/web/layouts/base/base.types";

interface Props {
    links: LinkType[]
}

export const Footer: FC<Props> = ({links}) => {
  return (
      <UIFooter>
        <Container>
          <S.newGrid container>
            <S.newGrid>
              <Links data={links}/>
            </S.newGrid>
          </S.newGrid>
        </Container>
      </UIFooter>
  );
};
import {config} from '@keaze/web/config/config';

export default class CmsApi {
    static async getPageFieldsFromApi(page: string) {
        const {cmsApi} = config;
        const response = await fetch(cmsApi+'/content', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({slug: page}),
        });
        return await response.json();
    }

    static async getLinksFromApi() {
        const {cmsApi} = config;
        const response = await fetch(cmsApi + '/links');
        return await response.json();
    }

    static async getFAQsFromApi() {
        const {cmsApi} = config;
        const response = await fetch(cmsApi + '/faqs');
        return await response.json();
    }
}
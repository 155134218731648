import { Scheme } from '../../common/interfaces';

export enum StartInterestRegisterActionNames {
  Set = 'SET_INTEREST_REGISTER',
}

export type StartInterestRegisterInitialState = {
  name: string;
  address: string;
  schemes: Scheme[];
  slug: string | null;
  propertyId: number | null;
  externalRef: string | null;
};

type SetInterestRegisterArgs = {
  name: string;
  address: string;
  schemes: Scheme[];
  slug: string | null;
  propertyId?: number | null;
  externalRef: string | null;
};

export type SetInterestRegisterAction = {
  type: StartInterestRegisterActionNames;
  payload: SetInterestRegisterArgs;
};

export type SetInterestRegister = (
  args: SetInterestRegisterArgs
) => SetInterestRegisterAction;

export type StartInterestRegisterAction = SetInterestRegisterAction;

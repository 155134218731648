import React, { FC, memo, useContext } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Button from '@material-ui/core/Button';

import { Loader, LoaderSize } from '@keaze/web/components/Loader';
import {
  Links as UILinks,
  List,
  ListItem,
  // SignOutButton,
  // SignInButton,
} from './links.styles';
import { RenderLink } from '../../base.types';
import { AuthContext, ToggleAuthDialog } from '@keaze/web/contexts/auth';
import { LinksProps } from './links.types';
import { isActiveLink } from '../utils';

type RenderRightSideArgs = {
  isAuthenticated: boolean;
  logout: () => void;
  toggleAuthDialog: ToggleAuthDialog;
};

type RenderRightSide = (args: RenderRightSideArgs) => JSX.Element;

// const renderAuthButton: RenderRightSide = ({
//   isAuthenticated,
//   logout,
//   toggleAuthDialog,
// }) => {
//   if (isAuthenticated) {
//     return (
//       <SignOutButton size="small" variant="contained" onClick={logout}>
//         Sign out
//       </SignOutButton>
//     );
//   }

//   return (
//     <>
      
//     </>
//   );
// };

const renderLink: RenderLink = ({ name, href }, isActive) => (
  <ListItem key={name} selected={isActive} disableGutters>
    <NextLink href={href} prefetch={false} passHref>
      <a>{name}</a>
    </NextLink>
  </ListItem>
);

export const Links: FC<LinksProps> = memo(({ data, className }) => {
  const router = useRouter();
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    logout,
    toggleAuthDialog,
  } = useContext(AuthContext);

  if (isAuthLoading) {
    return (
      <UILinks component="nav" className={className}>
        <Loader open={isAuthLoading} size={LoaderSize.Small} opacity={0} />
      </UILinks>
    );
  }

  return (
    <UILinks component="nav" className={className}>
      <Loader open={isAuthLoading} size={LoaderSize.Small} />
      <List disablePadding>
        {data.map((link) => renderLink(link, isActiveLink(router, link)))}
      </List>
      {/* {renderAuthButton({
        isAuthenticated,
        logout,
        toggleAuthDialog,
      })} */}
    </UILinks>
  );
});
